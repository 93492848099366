/** @see /isiWeb/web/v1/empresas/01/global-data */
export interface Empresa {
    emp: string;
    empDes: string;
    almOmi: string;
    almOmiDes: string;
    sefOmi: string;
    sefOmiDes: string;
    ven: number;
    venDes: string;
    age: number;
    ageDes: string;
    mec: number;
    mecnom: string;
}

export const EmptyEmpresa: Empresa = {
    emp: '',
    empDes: '',
    almOmi: '',
    almOmiDes: '',
    sefOmi: '',
    sefOmiDes: '',
    ven: -1,
    venDes: '',
    age: -1,
    ageDes: '',
    mec: -1,
    mecnom: '',
};
