import { Item } from '@/models/menu';
import { loadConfig } from '@/services/config.service';
import { url } from './api.service';

export const getMenuItems = (): Item[] => {
    const menuData = sessionStorage.getItem('isiparts.menu');

    if (!menuData) {
        return [] as Item[];
    }

    return JSON.parse(menuData) as Item[];
};

export const loadMenuItems = async (): Promise<void> => {
    try {
        const r = await fetch(url('/isiWeb/web/v1/menu'), {
            credentials: 'include',
        });

        if (!r.ok) {
            throw new Error(`Menu response status not OK: ${r.status}`);
        }

        const response = await r.json();
        let menu: Item[] = response.data as Item[];

        if (menu.length === 0) {
            menu = loadConfig().aside.defaultMenu;
        }

        sessionStorage.setItem('isiparts.menu', JSON.stringify(menu));
    } catch (error: any) {
        console.error('Failed to get menu', error);
    }
};
