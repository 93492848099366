import { defaultComponent } from '@/main';
import { Empresa, EmptyEmpresa } from '@/models/empresa';
import { PAGE_VISIT, TOAST_TRIGGER } from '@/models/events';
import { ToastError } from '@/models/toast';
import { EmptyUser, User } from '@/models/user';
import posthog from 'posthog-js';
import { url, getCompanyGlobalData, getGlobalData } from './api.service';
import { loadMenuItems } from './menu.service';

/**
 * Use keep-alive endpoint reponse status to check if the user is authenticated
 */
export const isAuthenticated = async (): Promise<boolean> => {
    const r = await fetch(url('/isiWeb/web/v1/keep-alive'), {
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
        },
    });

    const userData = sessionStorage.getItem('isiparts.global-data');
    const company = sessionStorage.getItem('isiparts.company-global-data');
    const menu = sessionStorage.getItem('isiparts.menu');

    if (r.ok && (!userData || !company || !menu)) {
        console.log('auth@isAuthenticated storeGlobalData');
        await storeGlobalData();
        /**
         * sessionStorage handles values instantly in memory but there is a delay until they are persisted.
         * We cannot use "fake" redirection using pinecone router to avoid a page reload, which causes
         * a race condition where the sessionStorage is not available after a page reload.
         * Therefore, we use window.location.href with a setTimeout to avoid the race condition.
         * We use 300ms to give the browser time to persist the sessionStorage modifications.
         */
        setTimeout(() => {
            console.log('Redirecting timeout');
            window.location.href = window.PineconeRouter.context.path;
        }, 300);
    }

    console.log('auth@isAuthenticated', r.status);

    return r.ok;
};

export const authenticationMiddleware = async () => {
    console.log('auth@handleAuthenticationRoutes init');

    await handleAuthenticationRedirections();

    document.addEventListener(PAGE_VISIT, async () => {
        console.log('auth@handleAuthenticationRoutes page visit', window.PineconeRouter.context);
        await handleAuthenticationRedirections();
    });
};

const handleAuthenticationRedirections = async () => {
    const loggedIn = await isAuthenticated();
    console.log('auth@handleAuthenticationRoutes loggedIn', loggedIn);

    if (!loggedIn && window.PineconeRouter.context.route !== '/login') {
        location.href = '/login';
    }

    if (loggedIn && window.PineconeRouter.context.route === '/login') {
        location.href = '/';
    }

    defaultComponent().loading = window.PineconeRouter.context.route === '/login' ? false : !loggedIn;
};

export const getAuthenticatedUser = (): User => {
    console.log('auth@getAuthenticatedUser');

    const userData = sessionStorage.getItem('isiparts.global-data');

    if (!userData) {
        console.error('User data not found');
        return EmptyUser;
    }

    return JSON.parse(userData) as User;
};

export const getAuthenticatedCompany = (): Empresa => {
    console.log('auth@getAuthenticatedCompany');

    const company = sessionStorage.getItem('isiparts.company-global-data');

    if (!company) {
        console.warn('Could not get authenticated company');
        return EmptyEmpresa;
    }

    return JSON.parse(company) as Empresa;
};

export const authenticate = async (username: string, password: string): Promise<void> => {
    console.log('auth@authenticate');

    try {
        const body = new URLSearchParams();
        body.set('j_username', username);
        body.set('j_password', password);

        const r = await fetch(url('/isiWeb/static/auth/j_spring_security_check'), {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body,
            credentials: 'include',
        });

        if (!r.ok) {
            throw new Error('Authentication failed');
        }

        // JSESSIONID is now set

        await storeGlobalData();

        const user = getAuthenticatedUser();
        console.log('authenticate@user', user);

        try {
            posthog.identify(user.usuario, user);
        } catch (error) {
            console.warn('Posthog identify failed', error);
        }
    } catch (error) {
        console.warn('auth@authenticate error', error);

        window.dispatchEvent(
            new CustomEvent(TOAST_TRIGGER, {
                detail: {
                    msg: error,
                    level: ToastError,
                },
            }),
        );
    }
};

const storeGlobalData = async (): Promise<void> => {
    const globalData = await getGlobalData();
    sessionStorage.setItem('isiparts.global-data', JSON.stringify(globalData));

    // TODO: override 'isiparts.company-global-data' when changing company
    const companyGlobalData = await getCompanyGlobalData(globalData.empOmi);
    sessionStorage.setItem('isiparts.company-global-data', JSON.stringify(companyGlobalData));

    await loadMenuItems();
};

export const logout = async () => {
    console.log('auth@logout');

    const r = await fetch(url('/isiWeb/static/auth/j_spring_security_logout'), {
        method: 'POST',
        credentials: 'include',
    });

    if (r.status !== 200) {
        throw new Error('Logout failed');
    }

    // JSESSIONID is now unset

    try {
        posthog.reset();
    } catch (error) {
        console.warn('Posthog reset failed', error);
    }

    Object.keys(sessionStorage).forEach((key) => {
        if (key.startsWith('isiparts.')) {
            sessionStorage.removeItem(key);
        }
    });

    /**
     * sessionStorage handles values instantly in memory but there is a delay until they are persisted.
     * Therefore, we use "fake" redirection using pinecone router to avoid a page reload, which causes
     * a race condition where the sessionStorage is not available after a page reload.
     */
    window.PineconeRouter.context.navigate('/login');
};
