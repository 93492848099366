import { Item } from '@/models/menu';
import { getApiHost } from '@/services/api.service';

/**
 * All configurable options must be defined in the Config interface
 */
export interface Config {
    home: {
        header: {
            image: string;
        };
    };
    aside: {
        defaultMenu: Item[];
    };
}

/**
 * Configurations are based on the API hostname
 */
const CUSTOMIZABLE_CLIENT_SUBDOMAINS: Record<string, () => Config> = {
    'test-api.isiparts.com': (): Config => testConfig(),
    'comabona-api.isiparts.com': (): Config => comabonaConfig(),
    'millarto-api.isiparts.com': (): Config => millartoConfig(),
};

const DEFAULT_SAT_MENU: Item[] = [
    {
        id: 1,
        text: 'SAT',
        icon: '',
        childs: [
            {
                id: 2,
                text: 'ÓRDENES DE TRABAJO',
                icon: 'folder',
                childs: [{ id: 3, text: 'Mis ORs', icon: '', route: 'or/me' }],
            },
        ],
    },
];

const DEFAULT_COMERCIAL_MENU: Item[] = [
    {
        id: 4,
        text: 'COMERCIAL',
        icon: 'group',
        childs: [
            {
                id: 5,
                text: 'POSIBLES CLIENTES',
                icon: 'folder',
                childs: [
                    { id: 6, text: 'REGISTRO DE POSIBLES CLIENTES', icon: 'group', route: 'presunto/new' },
                    { id: 7, text: 'MODIFICAR POSIBLES CLIENTES', icon: 'group', route: 'presunto/modify' },
                ],
            },
        ],
    },
];

/**
 * Load configuration based on the current API hostname
 */
export const loadConfig = (): Config => {
    const hostname = new URL(getApiHost()).hostname;
    console.log('hostname', hostname);

    if (hostname in CUSTOMIZABLE_CLIENT_SUBDOMAINS) {
        return CUSTOMIZABLE_CLIENT_SUBDOMAINS[hostname]();
    }

    return defaultConfig();
};

/**
 * This configuration applies to all clients unless overriden
 */
const defaultConfig = (): Config => {
    return {
        home: {
            header: {
                image: 'https://res.cloudinary.com/dygtknehl/image/upload/f_auto,q_auto:best/ISIParts_20logo_20home_prqswp',
            },
        },
        aside: {
            defaultMenu: [],
        },
    };
};

/**
 * Override default configuration for test-api.isiparts.com
 */
const testConfig = (): Config => {
    const config = defaultConfig();
    config.home.header.image =
        'https://res.cloudinary.com/dygtknehl/image/upload/co_rgb:ed772d,l_text:open%20sans_48_bold_normal_left:DEMO/fl_layer_apply,g_south_east,x_24,y_24/ISIParts_20logo_20home_prqswp';
    config.aside.defaultMenu = [...DEFAULT_COMERCIAL_MENU, ...DEFAULT_SAT_MENU];
    return config;
};

/**
 * Override default configuration for comabona-api.isiparts.com
 */
const comabonaConfig = (): Config => {
    const config = defaultConfig();
    config.aside.defaultMenu = DEFAULT_SAT_MENU;

    return config;
};

/**
 * Override default configuration for millarto-api.isiparts.com
 */
const millartoConfig = (): Config => {
    const config = defaultConfig();
    config.aside.defaultMenu = DEFAULT_COMERCIAL_MENU;

    return config;
};
